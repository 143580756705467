.logo {
  display: inline-flex;
  align-items: center;
  &__left,
  &__right {
    display: inline-flex;
    img {
      max-width: 100%;
    }
  }
  &__left {
    width: 100px;
  }
  &__right {
    width: 110px;
  }
  &__separator {
    display: inline-flex;
    width: 2px;
    height: 37px;
    margin: 0 14px;
    background-image: linear-gradient(180deg, #9490C1 0%, #120E40 33.85%, #120E40 69.79%, #9490C1 100%);;
  }

  &--compact {
    flex-direction: column;
    width: 100px;
    .logo__left {
      width: 100%;
      margin-bottom: 3px;
    }
    .logo__right {
      width: 100%;
    }
    .logo__separator {
      display: none;
    }
  }
}
