@import "app/javascript/packs/scss/variables";
.h-contacts {
  padding-top: 143px;
  padding-bottom: 160px;

  @media (max-width: $mobile-big) {
    padding-top: 20px;
    padding-bottom: 60px;
  }

  .container {
    position: relative;
    z-index: 1;
  }
  &__bg {
    position: absolute;
    top: -144px;
    left: unquote('max(-6.31944vw, -91px)');
    width: 103.333333vw;
    max-width: 1488px;
    filter: $drop-shadow-main;
    opacity: .3;
    pointer-events: none;
    user-select: none;
    z-index: -1;

    @media (max-width: $desktop) {
      width: 145.3125vw;
      left: unquote('max(-10.83984vw, -111px)');
    }

    @media (max-width: $mobile-big) {
      width: 100%;
      top: -20px;
      left: 0;
    }
    @media (max-width: $mobile) {
      top: 0;
    }
  }
  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -40px;
    margin-top: 45px;

    @media (max-width: $mobile-big) {
      margin-top: 30px;
      margin-bottom: -20px;
    }
    @media (max-width: $mobile) {
      flex-direction: column;
      margin-bottom: 0;
    }

    .contact-link {
      margin-right: 100px;
      margin-bottom: 40px;
      &:last-child {
        margin-right: 0;
      }

      @media (max-width: $desktop) {
        margin-right: 80px;
      }
      @media (max-width: $mobile-big) {
        margin-right: 60px;
        margin-bottom: 20px;
      }
      @media (max-width: $mobile) {
        margin-right: 0;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.contact-link {
  display: inline-flex;
  align-items: center;

  @media (hover: hover) {
    &:hover {
      .contact-link__text {
        text-decoration-color: $text-color;
      }
    }
  }

  &__ic {
    display: inline-flex;
    width: 57px;
    height: 57px;
    margin-right: 30px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
    @media (max-width: $mobile-big) {
      width: 48px;
      height: 48px;
      margin-right: 20px;
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
  }
  &__label {
    color: $text-color-opacity-50;
    margin-bottom: 2px;

    @media (max-width: $mobile-big) {
      font-size: 12px;
      line-height: 13px;
      margin-bottom: 4px;
    }
  }
  &__text {
    font-size: 24px;
    line-height: 31px;
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: .25s linear;
    transition-property: text-decoration-color;

    @media (max-width: $mobile-big) {
      font-size: $font-size-sm;
      line-height: $line-height-sm;
    }
  }
}
