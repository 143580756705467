@import "app/javascript/packs/scss/variables";
.header-main {
  height: $header-height;
  z-index: 50;
  &__wrap {
    height: $header-height;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    background-color: rgba(246, 252, 255, .7);
    backdrop-filter: blur(8px);
    padding-left: $grid-spacing;
    padding-right: $grid-spacing;
    transition: .2s linear;
    transition-property: background-color,backdrop-filter;
  }
  &__fix-block {
    .burger {
      position: fixed;
      z-index: 3;
      left: 40px;
      top: 25px;
    }
    .logo {
      position: fixed;
      z-index: 3;
      top: 16px;
      right: 20px;
      display: none;
    }
  }
  .logo {
    &__left,
    &__right {
      width: 85px;
    }
  }
  &--active {
    .header-main-menu {
      opacity: 1;
      visibility: visible;
    }
  }
  .header-user {
    display: flex;
    align-items: center;
    &__ava {
      width: 30px;
      height: 30px;
      background-color: $blue;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-weight: 900;
      font-size: 16px;
      line-height: 20px;
      color: rgba(246, 252, 255, .6);
      cursor: pointer;
    }
  }
}

.header-main-user {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  padding-bottom: 7px;
  &__link-wrap {
    a {
      font-size: 20px;
      line-height: 26px;
      font-weight: bold;
      letter-spacing: -0.04em;
      position: relative;
      margin-right: 32px;
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 3px;
        border-radius: 17px;
        background-color: currentColor;
        margin-top: 3px;
        transition: .25s linear;
        transition-property: opacity;
      }
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        color: $blue;
      }
      &:nth-child(2) {
        color: $orange;
      }
      @media (hover: hover) {
        &:hover {
          &::after {
            opacity: 0;
          }
        }
      }
    }
  }
}

.header-main-menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  width: 490px;
  background: rgba(236, 245, 251, 0.9);
  backdrop-filter: blur(10px);
  transition: .2s linear;
  transition-property: opacity,visibility;
  &--active {
    opacity: 1;
    visibility: visible;
  }
  &__inner {
    height: 100%;
    overflow: auto;
    padding: 157px 30px 30px 100px;
  }
  &__nav {
    list-style-type: none;
    padding: 0;
    margin-bottom: 42px;
    a {
      font-weight: bold;
      font-size: 36px;
      line-height: 47px;
    }
  }
  &__nav-item {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: .25s linear;
      transition-property: text-decoration-color;
      @media (hover: hover) {
        &:hover {
          text-decoration-color: $text-color;
        }
      }
    }
  }
  &__contacts-link {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    @media (hover: hover) {
      &:hover {
        span {
          text-decoration-color: $text-color;
        }
      }
    }
    span {
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: .25s linear;
      transition-property: text-decoration-color;
    }
  }
  &__contacts-ic {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    margin-right: 15px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .header-main-user {
    display: none;
  }
}

@media (max-width: $tablet) {
  .header-main {
    &__wrap {
      padding-left: $grid-spacing-sm;
      padding-right: $grid-spacing-sm;
    }
    &__fix-block {
      .burger {
        left: $grid-spacing-sm;
      }
    }
  }
  .header-main-menu {
    width: 390px;
    &__inner {
      padding: 100px 20px 20px 75px;
    }
  }
}
@media (max-width: $tablet) {
  .header-main {
    height: $header-height-sm;
    &__wrap {
      height: $header-height-sm;
    }
    &__fix-block {
      .burger {
        top: 15px;
      }
    }
  }
}
@media (max-width: $mobile-big) {
  .header-main {
    .logo {
      display: none;
      margin-left: auto;
    }
    &__fix-block {
      .logo {
        display: flex;
      }
    }
    &__wrap {
      display: flex;
      align-items: center;
      .header-main-user {
        display: none;
      }
    }
  }
  .header-main-menu {
    width: 100%;
    padding-top: $header-height-sm;
    &__inner {
      padding-top: 0;
      padding-left: $grid-spacing-sm;
    }
    &__nav {
      margin-bottom: 20px;
      a {
        font-size: 24px;
        line-height: 30px;
      }
    }
    &__nav-item {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .header-main-user {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
  }
}
