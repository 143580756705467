@import "app/javascript/packs/scss/variables";
.header {
  z-index: 50;
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $header-height;
    padding: 6px $grid-spacing 0;
  }
  .logo {
    display: none;
  }
  .burger {
    display: none;
  }
  &--active {
    .header-mob-menu {
      opacity: 1;
      visibility: visible;
    }
  }
}

.header-nav {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0;
  &__item {
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
    &--sub {
      position: relative;
      padding-left: 20px;
      &::before {
        content: '';
        position: absolute;
        left: 8px;
        top: 7px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $text-color;
      }
    }
    &--active {
      .header-nav {
        &__link {
          color: $text-color;
        }
      }
    }
  }
  &__link {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: $text-color-opacity-30;
    transition: .25s linear;
    transition-property: color;
    @media (hover: hover) {
      &:hover {
        color: $text-color;
      }
    }
  }
}

.header-user {
  display: flex;
  align-items: center;
  &__ava {
    width: 30px;
    height: 30px;
    background-color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    color: rgba(246, 252, 255, .6);
    cursor: pointer;
    text-transform: uppercase;
  }
  &__link {
    width: 22px;
    height: 22px;
    margin-left: 20px;
  }
}

.header-mob-menu {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: $header-height;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: .25s linear;
  transition-property: opacity,visibility;
  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $text-color-opacity-50;
  }
  &__wrap {
    width: 300px;
    min-height: 100%;
    position: relative;
    overflow: auto;
    background-color: $grey-light;
    padding: 20px;
  }
}

@media (max-width: $desktop-lg) {
  .header {
    &__wrap {
      padding-left: 40px;
    }
  }
}
@media (max-width: $desktop) {
  .header {
    &__wrap {
      padding-right: $grid-spacing-sm;
    }
  }
}
@media (max-width: $tablet) {
  .header {
    height: $header-height-sm;
    &__wrap {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      height: $header-height-sm;
      padding-top: 0;
      padding-left: $grid-spacing-sm;
      transition: .25s linear;
      transition-property: background-color;
    }
    .logo {
      display: flex;
      align-items: flex-start;
      margin-right: auto;
      &__left,
      &__right {
        width: 69px;
      }
    }
    .burger {
      display: flex;
      margin-right: 20px;
    }
    .header-nav {
      display: none;
    }
    &--scrolled,
    &--active,
    &--header-bg {
      .header {
        &__wrap {
          background-color: $grey-light;
        }
      }
    }
  }
  .header-mob-menu {
    display: flex;
    top: $header-height-sm;
    .header-nav {
      display: block;
    }
  }
  .header-nav {
    &__item {
      margin-right: 0;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
      &--active {
        .header-nav {
          &__link {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }
    &__link {
      color: $text-color;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3px;
        height: 3px;
        background-color: $orange;
        opacity: 0;
      }
    }
  }
}

@media (max-width: $mobile) {
 .header-mob-menu {
   &__overlay {
     display: none;
   }
   &__wrap {
     width: 100%;
   }
 }
}
