@import "app/javascript/packs/scss/variables";
@import '../../scss/mixins.scss';
footer {
  padding: $grid-spacing;
  color: $text-color-opacity-30;
  @media (max-width: $desktop) {
    padding-left: $grid-spacing-sm;
    padding-right: $grid-spacing-sm;
  }

  .wrapper-rubber__main & {
    @media (max-width: $desktop) {
      padding-left: $grid-spacing;
      padding-right: $grid-spacing-sm;
    }
    @media (max-width: $tablet) {
      padding-left: $grid-spacing-sm;
    }
  }
}
.footer-grid {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 20px;
  align-items: center;

  @media (max-width: $desktop) {
    grid-template-columns: auto;
    justify-items: center;
    grid-gap: 10px;

    .logo {
      transform: translateX(5px);
    }
  }
  @media (max-width: $mobile) {
    .wrapper & {
      line-height: 24px;
    }
  }

  &--flex {
    display: flex;
    justify-content: space-between;
    @media (max-width: $tablet) {
      display: grid;
      justify-content: center;
    }
  }
}

.footer-nav {
  display: flex;
  align-items: center;

  @media (max-width: $mobile) {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -10px;
    text-align: center;
  }

  li {
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $mobile) {
      margin-bottom: 10px;
      &:first-child {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  a {
    @include link-hover-underline($text-color-opacity-30);
  }
}
.footer-copyright {
  text-align: right;
}
