@import "app/javascript/packs/scss/variables";
.hero {
  padding: 75px 0 0;

  @media (max-width: $tablet-lg) {
    padding-top: 27px;
    padding-bottom: 39px;
  }

  h1 {
    margin-bottom: 45px;

    @media (max-width: $tablet-lg) {
      margin-bottom: 33px;
    }
  }
  p {
    font-size: 24px;
    line-height: 31px;

    @media (max-width: $tablet-lg) {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.04em;
    }
  }

  &__wrap {
    display: flex;

    @media (max-width: $tablet-lg) {
      display: block;
    }
  }
  &__content {
    width: 450px;
    margin-right: 60px;
    flex-shrink: 0;

    @media (max-width: $tablet-lg) {
      max-width: none;
      margin-right: 0;
    }

    @media (min-width: $mobile) and (max-width: $tablet-lg) {
      width: 100%;
    }
  }
  &__img-block {
    @media (min-width: $desktop-lg) {
      & img {
        max-height: 100%;
      }
    }
    @media (max-width: $tablet-lg) {
      margin-top: 30px;
    }
  }
  &__img-item {
    filter: $drop-shadow-main;
  }
}
