@import "app/javascript/packs/scss/variables";
.info-income {
  padding: 65px 0 113px;

  @media (max-width: $mobile-big) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  [data-tab-nav] {
    list-style-type: none;
    display: flex;
    align-items: center;
    padding: 0 0 14px;
    margin-top: 0;
    margin-left: -$grid-spacing;
    margin-right: -$grid-spacing;
    margin-bottom: 21px;
    white-space: nowrap;
    overflow: auto;
    &::before, &::after {
      content: '';
      display: inline-block;
      width: $grid-spacing;
      height: 1px;
      flex-shrink: 0;
      white-space: nowrap;
      @media (max-width: $desktop) {
        width: $grid-spacing-sm;
      }
    }

    @media (max-width: $desktop) {
      margin-bottom: 30px;
      margin-left: -$grid-spacing-sm;
      margin-right: -$grid-spacing-sm;
    }
    @media (max-width: $mobile-big) {
      padding-bottom: 3px;
    }
  }
  [data-tab-link] {
    font-weight: bold;
    font-size: 56px;
    line-height: 57px;
    color: $text-color-opacity-50;
    cursor: pointer;
    position: relative;
    margin-right: 70px;
    transition: .25s linear;
    transition-property: color;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -14px;
      height: 5px;
      background-color: $orange;
      border-radius: 5px;
      opacity: 0;
      transition: .25s linear;
      transition-property: opacity;
    }
    &:last-child {
      margin-right: 0;
    }
    @media (hover: hover) {
      &:hover {
        color: $text-color;
      }
    }
    &.is-active {
      color: $text-color;
      &::after {
        opacity: 1;
      }
    }

    @media (max-width: $mobile-big) {
      font-size: 36px;
      line-height: 47px;
      margin-right: 60px;
      &::after {
        bottom: -3px;
      }
    }

    @media (max-width: $mobile) {
      margin-right: 30px;
    }
  }
  &__grid {
    display: flex;

    @media (max-width: $tablet-lg) {
      display: block;
    }
  }
  &__txt-block {
    max-width: 470px;
    padding-top: 19px;
    margin-right: 75px;

    @media (max-width: $desktop) {
      padding-top: 0;
      margin-right: 54px;
    }
    @media (max-width: $tablet-lg) {
      max-width: none;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
  &__txt-title {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 30px;

    @media (max-width: $desktop) {
      margin-bottom: 20px;
    }
    @media (max-width: $mobile-big) {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.04em;
    }
  }
  &__table {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: $grey;
    padding: 80px 20px;

    @media (max-width: $desktop) {
      padding: 39px 16px;
    }
    @media (max-width: $mobile-big) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 30px;
      padding: 30px 0;
    }
    @media (max-width: $mobile) {
      row-gap: 44px;
    }
  }
  &__table-item {
    font-size: 24px;
    line-height: 36px;
    position: relative;
    padding: 0 35px;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 7px;
      width: 1px;
      height: 52px;
      background-color: #C2C2C2;
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
      &::after {
        display: none;
      }
    }

    @media (max-width: $desktop) {
      padding: 0 17px;
    }
    @media (max-width: $tablet-lg) {
      padding: 0 40px;
    }
    @media (max-width: $mobile-big) {
      padding: 0 20px;
      text-align: center;
      &::after {
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &:first-child {
        padding: 0 20px;
      }
      &:last-child {
        padding: 0 20px;
      }
      &:nth-child(even) {
        &::after {
          display: none;
        }
      }
    }
    @media (max-width: $mobile) {
      font-size: 20px;
      line-height: 30px;
    }
  }
  &__table-item-title {
    color: $blue;
    font-weight: bold;
  }
  &__table-item-txt {
    @media (max-width: $mobile) {
      font-size: 16px;
    }
  }
}
