@import "app/javascript/packs/scss/variables";
.i-checkbox {
  display: block;
  font-size: 14px;
  line-height: 19px;
  &--error {
    .i-checkbox {
      &__custom {
        border-color: red;
      }
    }
  }
  &__label {
    cursor: pointer;
    display: inline-flex;
    align-items: flex-start;
    @media (hover: hover) {
      &:hover {
        .i-checkbox {
          &__custom {
            box-shadow: inset 0px 0px 14px rgba(0, 168, 137, 0.1);
          }
          &__text {
            color: $text-color;
          }
        }
      }
    }
  }
  &__custom {
    position: relative;
    border: 1px solid $grey;
    border-radius: 5px;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    background-color: $grey;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    transition: .3s ease;
    transition-property: background-color, box-shadow;
    svg {
      fill: $green;
      transform: scale(0);
      transition: all .3s cubic-bezier(.18,.89,.32,1.28);
    }
  }
  &__text {
    color: $text-color-opacity-70;
    text-align: left;
    transition: all .3s cubic-bezier(.18,.89,.32,1.28);
  }
  input {
    display: none;
    &:checked {
      & ~ .i-checkbox {
        &__custom {
          svg {
            transform: scale(1);
          }
        }
        &__text {
          color: $text-color;
        }
      }
    }
    &.disabled, &:disabled {
      & ~ .i-checkbox {
        &__text {
          color: $text-color-opacity-50;
        }
      }
    }
    &.error ~ .i-checkbox__custom {
      border-color: red;
    }
  }
  label.error {
    display: none !important;
  }
  &.disabled {
    pointer-events: none;
    .i-checkbox {
      &__text {
        color: $text-color-opacity-50;
      }
    }
  }
}
