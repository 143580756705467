@import "app/javascript/packs/scss/variables";
.input-group-copy {
  display: flex;
  align-items: center;
  &__btn {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    background: none;
    padding: 0;
    margin-left: 20px;
    transition: .25s linear;
    transition-property: color;
    @media (hover: hover) {
      &:hover {
        color: $text-color;
      }
    }
    @media (max-width: $mobile-400) {
      margin-left: 10px;
    }
  }
  input {
    flex: 1;
    @media (max-width: $mobile-400) {
      width: calc(100% - 30px);
    }
  }
  &--blue-link {
    .input-group-copy {
      &__btn {
        color: $text-color-opacity-50;
        @media (hover: hover) {
          &:hover {
            color: $text-color;
          }
        }
      }
    }
    input {
      font-size: 12px;
      line-height: 13px;
      height: 43px;
      letter-spacing: -0.03em;
      color: $blue;
    }
  }
}
