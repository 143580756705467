@import "app/javascript/packs/scss/variables";
.h-advantages {
  padding-top: 75px;
  padding-bottom: 150px;

  @media (max-width: $tablet) {
    .tablet-hidden {
      display: none;
    }
  }

  @media (max-width: $mobile-big) {
    padding-top: 47px;
    padding-bottom: 70px;
  }

  @media (max-width: $mobile) {
    padding-bottom: 80px;
    .tablet-hidden {
      display: initial;
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }
  &__bg {
    position: absolute;
    top: -15px;
    width: 937px;
    filter: $drop-shadow-main;
    opacity: .5;
    pointer-events: none;
    user-select: none;
    z-index: -1;

    @media (max-width: $mobile-big) {
      top: 69px;
      width: calc(100% - 40px);
      max-width: 560px;
      margin: 0 auto;
    }
    @media (max-width: $mobile) {
      top: 45px;
      width: calc(100% - 24px);
    }
  }
  .union-1 {
    left: -416px;

    @media (max-width: $desktop) {
      left: -527px;
    }
    @media (max-width: $mobile-big) {
      left: 0;
      right: 0;
    }
  }
  .union-2 {
    right: -589px;

    @media (max-width: $desktop) {
      right: -654px;
    }
    @media (max-width: $mobile-big) {
      display: none;
      left: 0;
      right: 0;
    }
    @media (max-width: $mobile) {
      display: block;
      top: auto;
      bottom: 116px;
    }
    @media (max-width: $mobile-400) {
      bottom: 245px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 60px 127px;
    justify-content: flex-start;
    margin-top: 65px;

    @media (max-width: $desktop) {
      column-gap: 100px;
    }
    @media (max-width: $tablet) {
      grid-gap: 40px;
    }
    @media (max-width: $mobile-big) {
      column-gap: 20px;
      margin-top: 43px;
    }
    @media (max-width: $mobile) {
      grid-template-columns: auto;
      grid-gap: 50px;
    }
  }
}

.advantage-item {
  max-width: 434px;
  &__ic {
    width: 130px;
    height: 110px;
    margin-bottom: 30px;
    img {
      max-width: 100%;
      max-height: 100%;
    }

    @media (max-width: $mobile-big) {
      margin-bottom: 20px;
    }
  }
  h3 {
    font-weight: 400;
    margin-bottom: 10px;

    @media (max-width: $mobile-big) {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -.04em;
    }
    @media (max-width: $mobile) {
      margin-right: -10px;
    }
  }
}
