@import "app/javascript/packs/scss/variables";
@import "app/javascript/packs/scss/mixins";
.table-block {
  position: relative;
  margin-left: -20px;
  margin-right: -10px;
  padding-bottom: 40px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    width: 10px;
    background-color: $grey-light;
    z-index: 3;
  }
  &__wrap {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    @include scrollbar();
    padding-left: 10px;
    padding-right: 10px;
    &::-webkit-scrollbar-button:single-button:horizontal:decrement {
      width: 20px;
      visibility: hidden;
    }
    &::-webkit-scrollbar {
      background: none;
    }
    &::after {
      content: '';
      position: absolute;
      left: 20px;
      right: 10px;
      bottom: 0;
      height: 1px;
      background-color: #DAE8F0;;
    }
  }
  &.table-block--empty {
    .table-block {
      &__wrap {
        &::after {
          display: none;
        }
      }
    }
  }
  &.shadow-left,
  &.shadow-right {
    .table-block {
      &__wrap {
        &::after {
          display: none;
        }
      }
    }
    td,th {
      &:first-child {
        min-width: 150px;
        padding-right: 70px;
      }
    }
  }
  &.table-block-with-total {
    .shadow-block {
      &__btn {
        top: 73px;
      }
    }
  }
  &--simple {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 0;
    &::before {
      background-color: #F0F8FC;
      z-index: 5;
    }
    .table-block {
      &__wrap {
        &::after {
          display: none;
        }
      }
    }
    &.shadow-left,
    &.shadow-right {
      td,th {
        &:first-child {
          min-width: 138px;
          padding-right: 20px;
        }
      }
    }
    .shadow-block {
      &__btn {
        width: 20px;
        left: 126px;
        background-color: #F0F8FC;
        pointer-events: none;
        &::before {
          width: 10px;
          box-shadow: none;
          background: #120E40;
          opacity: .05;
          left: 10px;
        }
        span {
          width: 20px;
          background-color: #F0F8FC;
        }
      }
      &__btn-right {
        display: none;
      }
    }
  }
}
.table {
  display: table;
  width: 100%;
  white-space: nowrap;
  border-collapse: collapse;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: -0.03em;

  td,
  th {
    position: relative;
    padding: 12px 15px 12px 0;
    vertical-align: top;
    text-align: right;
    &:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: $grey-light;
      text-align: left;
      padding-left: 10px;
      &::after {
        left: 10px;
      }
    }
    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &.is-hide {
      display: none;
    }
    &.table__td-center {
      text-align: center;
    }
  }

  th {
    font-weight: normal;
    color: $text-color-opacity-50;
    padding-top: 7px;
    padding-bottom: 15px;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-bottom: 1px solid #DAE8F0;
    }
  }
  td {
    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  tbody {
    tr:not(.table__tr-total) {
      position: relative;
      border-radius: 5px;
      @media (hover: hover) {
        &:hover {
          background: #fff;
          box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
          z-index: 2;
          td {
            &:first-child {
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  &__tr-total {
    font-weight: bold;
    td {
      padding-top: 15px;
      padding-bottom: 15px;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid #DAE8F0;
      }
    }
  }
  &__tr-template {
    display: none;
  }
  &--simple {
    width: auto;
    th, td {
      &:first-child {
        background-color: #F0F8FC;
        width: 136px;
      }
      &:last-child {
        &::after {
          right: 10px;
        }
      }
    }
    tbody {
      tr:not(.table__tr-total) {
        @media (hover: hover) {
          &:hover {
            z-index: 4;
          }
        }
      }
    }
  }
}
.table-block ~ .btn-more {
  display: block;
  margin: 40px auto 0;
}

.shadow-block {
  position: relative;
  &__btn {
    position: absolute;
    width: 50px;
    top: 30px;
    bottom: 15px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: .2s linear;
    transition-property: opacity,visibility;
    &::before {
      content: '';
      position: absolute;
      top: 20px;
      bottom: 10px;
      left: 26px;
      width: 45px;
      border-radius: 5px;
      background-color: #E6E6E6;
      filter: blur(10px);
    }
    span {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: $grey-light;
    }
    img {
      position: relative;
    }
  }
  &__btn-left {
    left: 108px;
  }
  &__btn-right {
    right: 0;
    &::before {
      left: auto;
      right: 26px;
    }
    img {
      transform: rotate(180deg);
    }
  }
  &.shadow-left {
    .shadow-block {
      &__btn-left {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &.shadow-right {
    .shadow-block {
      &__btn-right {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

@media (max-width: $tablet) {
  .shadow-block {
    &__btn {
      top: 31px;
      bottom: 20px;
      width: 20px;
      left: 100px;
      background-color: $grey-light;
      pointer-events: none;
      &::before {
        width: 10px;
        box-shadow: none;
        background: #120E40;
        opacity: 0.09;
        left: 22px;
      }
      img {
        display: none;
      }
      span {
        width: 20px;
      }
    }
    &__btn-right {
      display: none;
    }
  }
  .table {
    &__tr-total {
      max-height: 43px;
      height: 43px;
    }
  }
  .table-block {
    margin-left: -10px;
    margin-right: 0;
    &__wrap {
      padding-left: 0;
      padding-right: 0;
    }
    &.shadow-left,
    &.shadow-right {
      td,th {
        &:first-child {
          min-width: 120px;
          max-width: 120px;
          width: 120px;
          padding-right: 20px;
        }
      }
    }
    &.table-block-with-total {
      .shadow-block {
        &__btn {
          top: 36px;
          bottom: 20px;
          &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 41px;
            height: 1px;
            background-color: #DAE8F0;
          }
        }
      }
    }
    &--simple {
      &.shadow-left,
      &.shadow-right {
        td,th {
          &:first-child {
            min-width: 138px;
            max-width: 138px;
            width: 138px;
            padding-right: 20px;
          }
        }
      }
      .shadow-block {
        &__btn {
          left: 118px;
        }
      }
    }
  }
}

.table-pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  & .prev, & .next {
    margin: 0 17px;
    width: 10px;
    height: 10px;
    border-right: 2px solid black;
    border-top: 2px solid black;
  }

  & .prev {
    transform: rotate(225deg);
  }

  & .next {
    transform: rotate(45deg);
  }

  & .pagy-nav.pagination {
    text-align: center;

    & .page {
      font-weight: bold;
      margin: 0 10px;
      opacity: 0.3;

      &.prev, &.next {
        display: none;
      }

      &.active {
        opacity: 1;
        padding-bottom: 1px;
        border-bottom: 3px solid #FF6C3E;
      }
    }
  }
}
