@import "app/javascript/packs/scss/variables";
.header-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  background-color: #fff;
  width: $header-menu-width;
  z-index: 40;
  transition: .2s linear;
  transition-property: width;
  &__wrap {
    height: 100%;
    overflow: auto;
  }
  &__inner {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 35px 0 20px;
  }
  .logo {
    justify-content: center;
    margin-bottom: 67px;
    &__left {
      width: 76px;
    }
    &__separator {
      height: 29px;
      margin: 0 17px;
    }
    &__right {
      width: 86px;
    }
    &--compact {
      width: auto;
      .logo {
        &__left {
          width: 66px;
        }
        &__right {
          width: 66px;
        }
      }
    }
  }
}

.header-menu-logo {
  width: 196px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 29px;
  margin-bottom: 56px;
  margin-left: auto;
  margin-right: auto;
  transition: .2s linear;
  transition-property: width;
  &__left {
    display: flex;
    flex-shrink: 0;
    position: relative;
    img {
      width: 76px;
      transition: .2s linear;
      transition-property: width, opacity;
      &:nth-child(2) {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 100%;
        margin-top: 3px;
        width: 66px;
      }
    }
  }
  &__separator {
    height: 29px;
    width: 1px;
    background: linear-gradient(180deg, #9490C1 0%, #120E40 33.85%, #120E40 69.79%, #9490C1 100%);
    display: block;
    margin: 0 17px;
    transition: .2s linear;
    transition-property: opacity,width,margin;
  }
  &__right {
    display: flex;
    align-items: center;
    transition: .2s linear;
    transition-property: opacity;
    img {
      width: 85px;
    }
  }
}

.header-menu-nav {
  list-style-type: none;
  overflow: hidden;
  color: $text-color-opacity-50;
  padding: 10px 0;
  margin-bottom: auto;
  &__item {
    position: relative;
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }
    &--active {
      color: $text-color;
      .header-menu-nav {
        &__main-link {
          &::before {
            opacity: 1;
          }
        }
      }
      &.header-menu-nav__item-with-sub {
        .header-menu-nav {
          &__main-link {
            &::before {
              opacity: 0;
            }
          }
        }
      }
    }
  }
  &__item-with-sub {
    .header-menu-nav {
      &__sub {
        display: none;
      }
    }
    &.header-menu-nav__item--active {
      .header-menu-nav {
        &__main-link {
          margin-bottom: 22px;
        }
        &__sub {
          display: block;
        }
        &__arrow {
          transform: none;
        }
      }
    }
  }
  &__main-link {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
    padding: 0 40px;
    transition: .25s linear;
    transition-property: padding, color, margin;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: -10px;
      bottom: -10px;
      width: 3px;
      background-color: $orange;
      opacity: 0;
      transition: .25s linear;
      transition-property: opacity;
    }
    @media (hover: hover) {
      &:hover {
        color: $text-color;
      }
    }
  }
  &__ic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    flex-shrink: 0;
  }
  &__arrow {
    display: flex;
    transform: rotate(-90deg);
    margin-left: 15px;
  }
  &__txt {
    padding-left: 12px;
    transition: .2s linear;
    transition-property: opacity, width, padding;
    white-space: nowrap;
  }
  &__sub {
    position: relative;
    transition: .2s linear;
    transition-property: opacity, height, padding;
    padding-left: 50px;
  }
  &__sub-wrap {
    border-left: 1px solid #C4C4C4;
    transition: .25s linear;
    transition-property: padding, border-color;
  }
  &__sub-item {
    position: relative;
    height: 24px;
    padding-left: 25px;
    padding-right: 20px;
    color: $text-color-opacity-50;
    margin-bottom: 10px;
    transition: .25s linear;
    transition-property: color, padding;
    &:last-child {
      margin-bottom: 0;
    }
    @media (hover: hover) {
      &:hover {
        color: $orange;
      }
    }
    &--active {
      color: $orange;
      font-weight: bold;
      &::before {
        content: '';
        position: absolute;
        left: -2px;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: $orange;
      }
    }
  }
  &__sub-link {
    transition: .2s linear;
    transition-property: opacity;
  }
}

.header-menu-btn {
  border: none;
  background-color: $grey-light;
  color: $blue;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: block;
  margin-left: auto;
  margin-right: 20px;
  transition: .2s linear;
  transition-property: margin;
  svg {
    transition: .2s linear;
    transition-property: transform;
  }
}

@media (max-width: $desktop-lg) {
  .header-menu {
    width: $header-menu-width-short;
  }
  .header-menu-nav {
    &__item {
      &--active {
        .header-menu-nav {
          &__main-link {
            &::before {
              opacity: 1;
            }
          }
        }
        &.header-menu-nav__item-with-sub {
          .header-menu-nav {
            &__main-link {
              &::before {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    &__item-with-sub {
      .header-menu-nav {
        &__main-link {
          margin-bottom: 0;
        }
      }
    }
    &__txt {
      opacity: 0;
      width: 0;
      padding-left: 0;
      pointer-events: none;
    }
    &__sub {
      padding-left: 0;
      &::before {
        opacity: 0;
      }
    }
    &__sub-wrap {
      border-color: transparent;
    }
    &__sub-item {
      text-align: center;
      &::before {
        opacity: 0;
      }
    }
    &__main-link {
      padding-left: 51px;
      padding-right: 51px;
    }
  }
  .header-menu-logo {
    width: 66px;
    &__left {
      img {
        width: 66px;
        &:nth-child(2) {
          opacity: 1;
        }
      }
    }
    &__separator {
      opacity: 0;
      width: 0;
      margin: 0;
    }
    &__right {
      opacity: 0;
    }
  }
  .header-menu-btn {
    margin-right: 38px;
    svg {
      transform: scaleX(-1);
    }
  }
  .header-manager {
    margin-bottom: 0;
    &__wrap {
      opacity: 0;
      height: 0;
      pointer-events: none;
      padding-bottom: 30px;
    }
    &__ava {
      cursor: pointer;
      img {
        left: 37px;
      }
    }
  }
}
@media (max-width: $desktop) {
  .header-menu {
    &__inner {
      padding-top: 31px;
    }
  }
  .header-menu-nav {
    &__sub {
      padding-left: 0;
    }
  }
  .header-menu-logo {
    margin-bottom: 63px;
  }
}
@media (max-width: $tablet) {
  .header-menu {
    right: 0;
    width: auto;
    opacity: 0;
    visibility: hidden;
    background-color: $text-color-opacity-50;
    z-index: 51;
    transition: .25s linear;
    transition-property: opacity,visibility;
    &--active {
      opacity: 1;
      visibility: visible;
    }
    &__inner {
      padding-bottom: 0;
    }
    .header-menu-logo {
      display: none;
    }
    .header-menu-nav {
      display: none;
    }
    .header-menu-btn {
      display: none;
    }
  }
}
