@import "app/javascript/packs/scss/variables";
.i-radio {
  display: block;
  font-size: 14px;
  line-height: 19px;
  &__label {
    cursor: pointer;
    display: inline-flex;
    align-items: flex-start;
    @media (hover: hover) {
      &:hover {
        .i-radio {
          &__custom {
            box-shadow: inset 0px 0px 14px rgba(0, 168, 137, 0.1);
          }
        }
      }
    }
  }
  &__custom {
    position: relative;
    border: 2px solid rgba(0, 168, 137, 0.2);
    border-radius: 50%;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    background-color: rgba(#fff, 1);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    transition: .3s ease;
    transition-property: background-color, border, box-shadow;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $green;
      border-radius: 50%;
      transform: scale(0);
      transition: all .3s cubic-bezier(.18,.89,.32,1.28);
    }
  }
  &__text {
    text-align: left;
    transition: all .3s cubic-bezier(.18,.89,.32,1.28);
    white-space: nowrap;
  }
  input {
    display: none;
    &:checked {
      & ~ .i-radio {
        &__custom {
          border-color: $green;
          &::after {
            transform: scale(1);
          }
        }
      }
    }
    &.disabled, &:disabled {
      & ~ .i-radio {
        &__custom {
          border-color: gray;
          background-color: gray;
          opacity: .4;
          &::after {
            transform: scale(1);
          }
        }
      }
    }
    &.error ~ .i-radio__custom {
      border-color: red;
    }
  }
  &--error {
    .i-radio {
      &__custom {
        border-color: red;
      }
    }
  }
  label.error {
    display: none !important;
  }
}
