@import "variables";
@at-root {
  @-ms-viewport {
    width: device-width;
  }
}
[data-whatintent='touch'],
[data-whatintent='mouse'] {
  *:focus {
    outline: none;
  }
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

*::before, *::after {
  box-sizing: inherit;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
}
body {
  position: relative;
  font-family: $default-font;
  font-size: $font-size;
  line-height: $line-height;
  color: $text-color;
  overflow-x: hidden;
  text-align: left;
  cursor: default;
  word-break: break-word;
  background-color: $grey-light;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (max-width: $mobile-big) {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
  }
}

[tabindex='-1']:focus {
  outline: none !important;
}

[role='button'] {
  cursor: pointer;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

a,
button,
[role='button'],
input,
label,
select,
textarea {
  touch-action: manipulation;
  margin: 0;
}
a {
  text-decoration: none;
  color: inherit;
}
button,
input {
  overflow: visible;
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
input[type=submit] {
  width: auto;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="search"] {
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  resize: vertical;
}
svg {
  fill: currentColor;
  a &,
  button & {
    pointer-events: none;
  }
  &:not(:root) {
    overflow: hidden;
  }
}
img {
  max-width: 100%;
  height: auto;
  border: none;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 1em * 2;
  margin-bottom: 1em * 2;
  border: 0;
  border-top: 1px solid hsl(0, 0%, 70%);
}
p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: 0;
}
p {
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
  padding: 0;
  margin: 0;
}
address {
  font-style: normal;
}
ul, ol {
  margin: 0;
  &.no-style {
    padding: 0;
    list-style: none;
  }
}
li {
  line-height: inherit;
}
blockquote {
  padding: 0;
  margin: 0;
}
code {
  padding: 0.06em 0.3em;
  color: $text-color;
  background-color: rgba(#000, 0.08);
  border-radius: 3px;
}
kbd {
  padding: 0.06em 0.3em;
  color: $text-color;
  background-color: hsl(0, 0%, 90%);
  border-radius: 3px;
  kbd {
    padding: 0;
  }
}
pre {
  display: block;
  width: 100%;
  margin-bottom: 0;
  overflow-x: auto;
  tab-size: 2;
  background-color: rgba(#000, 0.08);
  code {
    background: transparent;
  }
}
[contenteditable=true]:empty:before {
  content: attr(data-placeholder);
  color: #131313;
  font-size: inherit;
  display: block;
}
select {
  appearance: none;
  &::-ms-expand {
    display: none;
  }
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: currentColor;
  margin: 0;
  padding: 0;
}

@mixin heading-size-1 {
  font-size: 56px;
  line-height: 57px;
  @media (max-width: $mobile-big) {
    font-size: 36px;
    line-height: 47px;
  }
}
@mixin heading-size-2 {
  font-size: 36px;
  line-height: 47px;
}
@mixin heading-size-3 {
  font-size: 24px;
  line-height: 31px;
}
@mixin heading-size-4 {
  font-size: 20px;
  line-height: 26px;
  @media (max-width: $mobile) {
    letter-spacing: -.04em;
  }
}

h1 {
  @include heading-size-1;
  &.heading-size-2 {
    @include heading-size-2;
  }
}
h2 {
  @include heading-size-2;
  &.heading-size-1 {
    @include heading-size-1;
  }
}
h3 {
  @include heading-size-3;
}
h4 {
  @include heading-size-4;
}

.container {
  max-width: 1320px;
  width: 100%;
  margin: auto;
  padding: 0 $grid-spacing;
  @media (max-width: $desktop) {
    padding-left: $grid-spacing-sm;
    padding-right: $grid-spacing-sm;
  }
}

.policy-heading {
  @include heading-size-4;

  @media (min-width: $mobile-mini) {
    @include heading-size-3;
  }
  @media (min-width: $mobile) {
    @include heading-size-2;
  }
}
