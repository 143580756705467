@import "app/javascript/packs/scss/variables";
.burger {
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  position: relative;
  transition: .5s ease-in-out;
  transition-property: transform, opacity, top, left, right, bottom;
  cursor: pointer;
  z-index: 2;
  &:hover {
    color: $orange;
    .burger {
      &__item {
        background-color: $orange;
      }
    }
  }
  &__wrap {
    width: 38px;
    height: 40px;
    position: relative;
  }
  &__item {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $orange;
    border-radius: 3px;
    opacity: 1;
    left: 1px;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    &:nth-child(1) {
      top: 8px;
    }
    &:nth-child(2) {
      top: 19px;
    }
    &:nth-child(3) {
      top: 19px;
    }
    &:nth-child(4) {
      top: 30px;
    }
  }
  &--active {
    .burger__item {
      &:nth-child(1) {
        top: 9px;
        width: 0;
        left: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
        opacity: 1;
      }
      &:nth-child(4) {
        top: 9px;
        width: 0;
        left: 50%;
      }
    }
  }
}
