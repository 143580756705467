@import "app/javascript/packs/scss/variables";
.header-manager {
  overflow: hidden;
  padding-top: 46px;
  margin-bottom: 27px;
  position: relative;
  transition: .2s linear;
  transition-property: margin,padding;
  &__ava {
    img {
      position: absolute;
      top: 20px;
      left: 60px;
      width: 52px;
      height: 52px;
      border-radius: 50%;
      background-color: $grey-light;
      filter: $drop-shadow-main;
      z-index: 1;
      object-fit: cover;
      object-position: bottom;
      transition: .2s linear;
      transition-property: left;
    }
  }
  &__wrap {
    min-width: 236px;
    max-width: 236px;
    border-radius: 10px;
    background-color: $grey-light;
    padding: 42px 15px 40px 40px;
    margin-left: 20px;
    transition: .1s linear;
    transition-property: opacity, height, padding;
  }
  &__name {
    font-weight: bold;
    margin-bottom: 3px;
  }
  &__position {
    font-size: 12px;
    line-height: 13px;
    letter-spacing: -0.03em;
    color: $text-color-opacity-50;
    margin-bottom: 19px;
  }
  &__contacts-link {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: -0.03em;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    @media (hover: hover) {
      &:hover {
        span {
          text-decoration-color: $text-color;
        }
      }
    }
    span {
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: .25s linear;
      transition-property: text-decoration-color;
    }
  }
  &__contacts-ic {
    display: flex;
    flex-shrink: 0;
    margin-right: 10px;
  }
  &__close-btn {
    display: none;
  }
}
.header-manager-mob {
  display: none;
  position: fixed;
  right: $grid-spacing-sm;
  bottom: 90px;
  background-color: $grey-light;
  filter: $drop-shadow-main;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  z-index: 39;
  padding: 8px 2px 0;
  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: contain;
    object-position: bottom;
  }
}

@media (max-width: $desktop-lg) {
  .header-manager {
    margin-bottom: 0;
    &__wrap {
      padding-bottom: 30px;
    }
    &__ava {
      img {
        left: 37px;
      }
    }
  }
}
@media (max-width: $desktop) {
  .header-manager {
    &__wrap {
      min-width: 200px;
      max-width: 200px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 20px;
    }
  }
}
@media (max-width: $tablet) {
  .header-manager-mob {
    display: flex;
  }
  .header-manager {
    background-color: #fff;
    border-radius: 13px 13px 0 0;
    padding: $grid-spacing-sm;
    margin-top: auto;
    &__ava {
      pointer-events: none;
      img {
        left: auto;
        right: 20px;
        top: 20px;
        width: 66px;
        height: 66px;
      }
    }
    &__wrap {
      opacity: 1;
      height: auto;
      pointer-events: auto;
      min-width: auto;
      max-width: none;
      width: 100%;
      background: none;
      padding: 20px 70px 0 0;
      margin: 0 0 40px 0;
    }
    &__name {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 5px;
    }
    &__close-btn {
      display: inline-block;
      width: 100%;
    }
  }
}
