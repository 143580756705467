@mixin transition($element: all, $time: .25s, $function: linear) {
  transition: $time $function;
  transition-property: #{$element};
}

@mixin transition-cubic($time: .25s, $function: cubic-bezier(1,0,0,1), $element: all) {
  transition: $element $time $function;
}

@mixin link-hover-underline($color: #120E40) {
  color: $color;
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: .25s linear;
  transition-property: text-decoration-color;
  @media (hover: hover) {
    &:hover {
      text-decoration-color: $color;
    }
  }
}

@function convertVH($val) {
  @return calc(var(--vh, 1vh) * #{$val});
}

@mixin heightVH($val: 100, $pre: '') {
  $vh: calc(var(--vh, 1vh) * #{$val});
  @if $pre != '' {
    #{$pre}-height: $val+vh;
    #{$pre}-height: $vh;
  } @else {
    height: $val+vh;
    height: $vh;
  }
}

@mixin scrollbar($width: 3px, $rail-color: #DAE8F0, $bar-color: #FF6C3E) {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  scrollbar-color: $bar-color $rail-color;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-image: linear-gradient(to top, transparent 33.333333%, #DAE8F0 33.333333%, #DAE8F0 66.666666%, transparent 66.666666%);
  }
  &::-webkit-scrollbar-thumb:vertical {
    border-radius: 3px;
    background-color: $bar-color;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    border-radius: 3px;
    background-color: $bar-color;
  }
}
@mixin scrollbarButton($width: 20px, $hidden: true) {
  &::-webkit-scrollbar-button:single-button:horizontal:increment,
  &::-webkit-scrollbar-button:single-button:horizontal:decrement {
    width: $width;
    border-radius: 3px;
    background: #ee3e01;
    @if $hidden {
     visibility: hidden;
    }
  }
}

@mixin scrollbar2($width: 3px, $rail-color: #DAE8F0, $bar-color: #FF6C3E) {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  scrollbar-color: $bar-color $rail-color;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
    border-radius: 3px;
    background: none;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: none;
  }
  &::-webkit-scrollbar-thumb:vertical {
    border-radius: 3px;
    background-color: $bar-color;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    border-radius: 3px;
    background-color: $bar-color;
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
