@import "app/javascript/packs/scss/variables";
@import "app/javascript/packs/scss/mixins";
input, textarea {
  font-family: inherit;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  background-color: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 8.77813px 10.8397px rgba(123, 141, 164, 0.0525061), 0px 3.65278px 4.51063px rgba(123, 141, 164, 0.0365489);
  border-radius: 5px;
  outline: none;
  padding: 0 13px;
  margin: 0;
  transition: .2s linear;
  transition-property: border-color;
  &.input-dark {
    background-color: $grey-light;
    border-color: $grey-light;
    @include placeholder {
      color: $text-color-opacity-20;
    }
  }
  &.input-dark2 {
    background-color: rgba(236, 245, 251, .9);
    border-color: rgba(236, 245, 251, .9);
    box-shadow: none;
    @include placeholder {
      color: $text-color-opacity-20;
    }
  }
}
input {
  height: 54px;
}
textarea {
  height: 240px;
  resize: none;
  max-width: 630px;
  width: 100%;
  border-radius: 10px;
  padding: 30px;
}

.input-group {
  position: relative;
  &__label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
    line-height: 24px;
    opacity: .2;
    pointer-events: none;
    transition: all .25s linear;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    padding: 15px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: none;
    }
    span.mob {
      display: none;
    }
  }
  &__eye {
    position: absolute;
    right: 16px;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .2;
    cursor: pointer;
    transition: .25s linear;
    transition-property: opacity;
    @media (hover: hover) {
      &:hover {
        opacity: 1;
      }
    }
    svg {
      &:nth-child(2) {
        display: none;

      }
    }
    &--active {
      bottom: 16px;
      svg {
        &:first-child {
          display: none;
        }
        &:nth-child(2) {
          display: block;
        }
      }
    }
  }
  &__search-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 15px;
  }
  &__remove-btn {
    flex-shrink: 0;
    background: none;
    outline: none;
    border: none;
    padding: 0;
    margin-left: 40px;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  input,
  textarea {
    padding-top: 12px;
    &:focus, &:active {
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
        & ~ .input-group__label {
          display: none;
        }
      }
    }
    &.error {
      border-color: $red;
      & ~ .input-group__label {
        color: $red;
        opacity: 1;
      }
    }
    &.valid,
    &[aria-invalid=false] {
      border-color: $green;
      & ~ .input-group__label {
        color: $green;
        opacity: 1;
      }
    }
  }
  &__error,
  label.error {
    font-size: 11px;
    line-height: 11px;
    color: $red;
    letter-spacing: -0.03em;
    position: absolute;
    left: 15px;
    top: 100%;
  }
  &__input-wrap {
    position: relative;
    display: flex;
    align-items: center;
  }
  &.input-group-textarea {
    min-height: 56px;
    height: 112px;
    textarea {
      height: 100%;
      padding: 22px 20px 6px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 12px 20px 6px;
      }
    }
  }
  &.disabled {
    opacity: .8;
    cursor: not-allowed;
    input {
      pointer-events: none;
    }
  }
  &--search {
    position: relative;
    display: flex;
    align-items: center;
    height: 35px;
    box-shadow: 0px 29.4839px 36.4082px rgba(123, 141, 164, 0.0774939), 0px 8.77813px 10.8397px rgba(123, 141, 164, 0.0525061), 0px 3.65278px 4.51063px rgba(123, 141, 164, 0.0365489);
    background-color: #fff;
    border-radius: 5px;
    padding: 0 20px;
    input {
      min-width: 195px;
      max-width: 300px;
      background: none;
      border-radius: 0;
      box-shadow: none;
      border: none;
      outline: none;
      height: 100%;
      padding: 0;
      @include placeholder {
        color: $text-color-opacity-30;
      }
    }
    &.input-group--notempty,
    &.input-group--focus {
      .input-group {
        &__remove-btn {
          display: flex;
        }
      }
    }
  }
  &--default {
    .input-group {
      &__label {
        position: relative;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        opacity: .7;
        margin-bottom: 10px;
      }
    }
    input {
      padding-top: 0;
    }
    &.input-group--notempty, &.input-group--focus {
      .input-group {
        &__label {
          font-size: 16px;
          line-height: 19px;
          top: auto;
        }
      }
    }
  }
  &--notempty, &--focus {
    .input-group {
      &__label {
        font-size: 12px;
        line-height: 13px;
        padding-top: 10px;
      }
    }
  }
}

input:-webkit-autofill ~ .input-group__label {
  font-size: 12px;
  line-height: 13px;
}

@media (max-width: $mobile-mini) {
  .input-group {
    &__label {
      span.desk {
        display: none;
      }
      span.mob {
        display: inline;
      }
    }
  }
}
