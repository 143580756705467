@mixin fontRuble($f-name, $f-style, $f-weight, $f-url) {
	@font-face {
		font-family: $f-name;
		font-style:  $f-style;
		font-weight: $f-weight;
		src: url('../fonts/#{$f-url}.ttf') format('truetype');
	}
}
@mixin font($f-name, $f-style, $f-weight, $f-url) {
	@font-face {
	  font-family: $f-name;
	  font-style:  $f-style;
	  font-weight: $f-weight;
	  src: url('fonts/#{$f-url}.woff2') format('woff2'),
	  url('fonts/#{$f-url}.woff') format('woff'),
	  url('fonts/#{$f-url}.ttf') format('truetype'),
	  url('fonts/#{$f-url}.eot'),
	  url('fonts/#{$f-url}.eot?#iefix') format('embedded-opentype');
	}
}

@include font('Formular', normal, 400, 'Formular-Regular');
@include font('Formular', normal, 700, 'Formular-Bold');
@include font('Formular', normal, 900, 'Formular-Black');
