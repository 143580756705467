@import "app/javascript/packs/scss/variables";
.tippy-box {
  border-radius: 5px;
  &[data-theme~=light] {
    filter: $drop-shadow-main;
    box-shadow: none;
  }
}
.tippy-content {
  text-align: center;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: -0.03em;
  padding: 15px;
  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: $text-color-opacity-50;
    text-decoration: underline;
    text-decoration-color: currentColor;
    transition: .25s linear;
    transition-property: text-decoration;
    @media (hover: hover) {
      &:hover {
        text-decoration-color: transparent;
      }
    }
  }
}
.tippy-svg-arrow {
  svg {
    fill: inherit;
  }
}
