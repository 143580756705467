$mobile-400:    400px;
$mobile-mini:   414px;
$mobile-sm:     479px;
$mobile:        575px;
$mobile-big:    767px;
$tablet:        991px;
$tablet-lg:     1023px;
$desktop:       1199px;
$desktop-md:    1279px;
$desktop-lg:    1439px;
$desktop-lg-min-width: 1440px;
$media-mh-800:  800px;
$media-mh-1000: 1000px;

$header-height: 90px;
$header-height-sm: 70px;
$header-menu-width-short: 126px;
$header-menu-width: 276px;
$header-menu-width-sm: 240px;

$grid-spacing: 40px;
$grid-spacing-sm: 20px;

$default-font: 'Formular', sans-serif;
$font-size: 16px;
$font-size-sm: 14px;
$line-height: 24px;
$line-height-sm: 18px;

$text-color: #120E40;
$text-color-opacity-10: rgba(18, 14, 64, 0.1);
$text-color-opacity-20: rgba(18, 14, 64, 0.2);
$text-color-opacity-30: rgba(18, 14, 64, 0.3);
$text-color-opacity-40: rgba(18, 14, 64, 0.4);
$text-color-opacity-50: rgba(18, 14, 64, 0.5);
$text-color-opacity-60: rgba(18, 14, 64, 0.6);
$text-color-opacity-70: rgba(18, 14, 64, 0.7);
$text-color-opacity-80: rgba(18, 14, 64, 0.8);
$red: #E4404A;
$grey: #ECF5FB;
$grey-light: #F6FCFF;
$grey-opacity-40: rgba(236, 245, 251, .4);
$grey-opacity-60: rgba(236, 245, 251, .6);
$orange: #FF6C3E;
$blue: #3555C5;
$green: #00A889;
$green-opacity-50:  rgba(0, 168, 137, 0.5);

$vertical-gradient: linear-gradient(to bottom, transparent, #120E40, transparent);
$drop-shadow-main: drop-shadow(0px 16px 20px rgba(123, 141, 164, 0.1))
                   drop-shadow(0px 3px 4px rgba(123, 141, 164, 0.07));
$box-shadow-main: 0px 5px 22px rgba(175, 207, 230, 0.1),
                  0px 2px 11px rgba(175, 207, 230, 0.07);
