@import "variables";
@import 'mixins.scss';
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.apple-fix {
  width: 100%;
  position: fixed;
}

.no-scroll {
  overflow: hidden;
}

.clearfix {
  &::after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @include heightVH(100, min);
  width: 100%;
  overflow-x: hidden;
  position: relative;

  &.sticky {
    overflow: unset;
  }

  .header {
    flex: 0 0 auto;
  }

  main {
    position: relative;
    flex: 1 0 auto;
  }

  .footer {
    flex: 0 0 auto;
  }
}

.bg-boss {
  position: absolute;
  top: 54px;
  left: 0;
  right: 0;
  margin: 0 auto;
  filter: $drop-shadow-main;
  opacity: .25;

  @media (max-width: $tablet) {
    left: -13px;
    right: -13px;
    height: auto;
  }
}

.split-wrapper {
  display: flex;
  min-height: 743px;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  position: relative;

  &__left,
  &__right {
    width: 50%;
    position: relative;
  }

  &__left {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $grey;
    padding-top: 59px;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__logo {
    z-index: 2;
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      flex-shrink: 0;
      width: 2px;
      height: 37px;
      background: linear-gradient(180deg, #9490C1 0%, #120E40 33.85%, #120E40 69.79%, #9490C1 100%);
      margin: 0 14px;
    }
  }

  &__logo-img {
    display: flex;
    align-items: center;
    width: 120px;

    &:first-child {
      justify-content: flex-end;
    }
  }
}

.wrapper-rubber {
  &__main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    @include heightVH(100, min);
    width: 100%;
    overflow-x: hidden;
    position: relative;
    padding-left: $header-menu-width;
    transition: .2s linear;
    transition-property: padding;
    .header {
      flex: 0 0 auto;
    }
    main {
      position: relative;
      flex: 1 0 auto;
    }
    .footer {
      flex: 0 0 auto;
    }
  }
  &__bg-text {
    position: absolute;
    top: 54px;
    left: 125px;
    right: 0;
    margin: 0 auto;
    filter: $drop-shadow-main;
    opacity: .25;

    @media (max-width: $tablet) {
      left: -13px;
      right: -13px;
      height: auto;
    }
  }
  main {
    .container {
      padding-top: 26px;
      padding-bottom: 20px;
    }

    .content {
      background: #ECF5FB;
      border-radius: 10px;
      padding: 30px;
      margin-top: 30px;
    }
  }
  &--js-no-trans {
    .header-menu {
      transition: none !important;
      & * {
        transition: none !important;
      }
    }
    .wrapper-rubber {
      &__main {
        transition: none !important;
      }
    }
  }
}

.is-default-hidden {
  display: none !important;
}

.pagination-long {
  .swiper-pagination-bullet {
    background-color: $orange;
    opacity: .3;
    height: 5px;
    border-radius: 5px;
    margin-right: 20px;
    transition: .25s linear;
    transition-property: opacity;

    &:last-child {
      margin-right: 0;
    }

    @media (hover: hover) {
      &:hover {
        opacity: 1;
      }
    }
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }
}

.fixed-link {
  position: fixed;
  right: 40px;
  bottom: 93px;
  z-index: 100;
}

.main-links {
  .link-border {
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.link-border {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.04em;
  color: $text-color-opacity-30;
  position: relative;
  transition: .25s linear;
  transition-property: color;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
    height: 3px;
    border-radius: 17px;
    background-color: $orange;
    opacity: 0;
  }
  &--active {
    color: $text-color;
    &::after {
      opacity: 1;
    }
  }
  @media (hover: hover) {
    &:hover {
      color: $text-color;
    }
  }
  &--sm {
    font-size: 16px;
    line-height: 19px;
    &::after {
      bottom: -5px;
    }
  }
}
.page-link {
  color: $blue;
  opacity: .8;
  transition: .25s linear;
  transition-property: color, opacity;
  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
}
.alert {
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 24px;
  color: $text-color;
  background-color: #fff;
  border: 2px solid rgba(0, 168, 137, 0.3);
  border-radius: 10px;
  padding: 18px;
  margin: 10px 0;
  &__icon {
    flex-shrink: 0;
    margin-right: 10px;
    margin-top: 1px;
  }
  h4 {
    color: $text-color;
    margin-bottom: 15px;
    .alert {
      &__icon {
        margin-top: 0;
        margin-bottom: -4px;
      }
    }
  }
  ul {
    padding: 0 0 0 20px;
    margin: 0;
  }
}
.btn-alert-close {
  display: flex;
  border: none;
  outline: none;
  background: none;
  flex-shrink: 0;
  color: $text-color-opacity-40;
  padding: 0;
  margin-left: auto;
  margin-top: 4px;
  transition: .25s linear;
  transition-property: color;
  @media (hover: hover) {
    &:hover {
      color: $text-color;
    }
  }
}
.alert-primary {
  border-color: rgba(53, 85, 197, 0.3);
}
.alert-danger {
  border-color: rgba(228, 64, 74, 0.3);
}
.u-tuc {
  text-transform: uppercase;
}
.u-fwb {
  font-weight: bold;
}
.u-tsm {
  font-size: 12px;
  line-height: 13px;
  letter-spacing: -0.03em;
}
.u-dfc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-blue {
  color: $blue;
}
.formula {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.04em;
  border-radius: 10px;
  background-color: $grey;
  text-align: center;
  padding: 40px 20px 40px;
}
.math-sign {
  font-weight: 300;
  font-size: 56px;
  line-height: 68px;
  color: $text-color-opacity-40;
  margin: 0 12px;
}
.math-el {
  text-align: left;
  display: inline-flex;
  flex-direction: column;
  padding-top: 10px;
}
.block-text-img {
  display: flex;
  margin: 40px 0;
  &__text {
    max-width: 300px;
    margin-right: 32px;
    h3 {
      .page-text-container & {
        margin-top: 0;
      }
    }
    h4 {
      letter-spacing: -0.04em;
      margin-bottom: 20px;
    }
  }
  &__img {
    flex-shrink: 0;
    filter: drop-shadow(0px 20px 30px rgba(123, 141, 164, 0.08))
    drop-shadow(0px 8px 10px rgba(123, 141, 164, 0.05));
  }
  &--long {
    .block-text-img {
      &__text {
        max-width: 375px;
        margin-right: 60px;
        p {
          max-width: 325px;
        }
      }
    }
  }
}
ol {
  ul {
    list-style: disc;
  }
}
.page-text-container {
  color: $text-color-opacity-80;
  max-width: 830px;
  margin-left: 0;
  & > h1 {
    margin-bottom: 50px;
  }
  .main-links {
    margin-bottom: 22px;
    & + .block-text-img {
      margin-top: 20px;
    }
  }
  .link-border {
    font-size: 36px;
    line-height: 47px;
    letter-spacing: normal;
    margin-right: 60px;
  }
  h3 {
    margin-top: 40px;
    margin-bottom: 23px;
  }
  p {
    margin-bottom: $line-height;
  }
  ul,ol {
    padding-left: 25px;
    margin-bottom: $line-height;
  }
  ol {
    ul {
      padding-left: 2px;
      margin-bottom: 0;
    }
  }
  ul {
    ol {
      padding-left: 0;
      margin-bottom: 0;
    }
  }
  & > *:last-child {
    margin-bottom: 0;
  }
  &--center {
    margin: 30px auto 0;

    @media (min-width: $tablet-lg) {
      margin-top: 60px;
    }
  }
}

@media (min-width: $desktop-lg-min-width) {
  .wrapper-rubber {
    &--desk-short {
      .header-menu {
        width: $header-menu-width-short;
      }
      .wrapper-rubber {
        &__main {
          padding-left: $header-menu-width-short;
        }
      }
      .header-menu-nav {
        &__item {
          &--active {
            .header-menu-nav {
              &__main-link {
                &::before {
                  opacity: 1;
                }
              }
            }
          }
        }
        &__item-with-sub {
          &--active {
            .header-menu-nav {
              &__sub-wrap {
                padding-left: 0;
                border-color: transparent;
              }
            }
          }
        }
        &__txt {
          opacity: 0;
          width: 0;
          padding-left: 0;
          pointer-events: none;
        }
        &__sub {
          padding-left: 0;
          &::before {
            opacity: 0;
          }
        }
        &__sub-item {
          text-align: center;
          &::before {
            opacity: 0;
          }
        }
        &__main-link {
          padding-left: 51px;
          padding-right: 51px;
        }
      }
      .header-menu-logo {
        width: 66px;
        &__left {
          img {
            width: 66px;
            &:nth-child(2) {
              opacity: 1;
            }
          }
        }
        &__separator {
          opacity: 0;
          width: 0;
          margin: 0;
        }
        &__right {
          opacity: 0;
        }
      }
      .header-menu-btn {
        margin-right: 38px;
        svg {
          transform: scaleX(-1);
        }
      }
      .header-manager {
        margin-bottom: 0;
        &__wrap {
          opacity: 0;
          height: 0;
          pointer-events: none;
          padding-bottom: 30px;
        }
        &__ava {
          cursor: pointer;
          img {
            left: 37px;
          }
        }
      }
    }
  }
}
@media (max-width: $desktop-lg) {
  .wrapper-rubber {
    &__main {
      padding-left: $header-menu-width-short;
    }
    &--tablet-long {
      .wrapper-rubber {
        &__main {
          padding-left: $header-menu-width;
        }
      }
      .header-menu {
        width: $header-menu-width;
      }
      .header-menu-btn {
        margin-right: 20px;
        svg {
          transform: none;
        }
      }
      .header-manager {
        margin-bottom: 47px;
        &__ava {
          cursor: auto;
          img {
            left: 43px;
          }
        }
        &__wrap {
          pointer-events: auto;
          opacity: 1;
          height: auto;
          padding-bottom: 40px;
        }
      }
      .header-menu-logo {
        width: 196px;
        &__left {
          img {
            width: 76px;
            &:nth-child(2) {
              opacity: 0;
            }
          }
        }
        &__separator {
          opacity: 1;
          width: 1px;
          margin: 0 17px;
        }
        &__right {
          opacity: 1;
        }
      }
      .header-menu-nav {
        &__main-link {
          padding-left: 40px;
          padding-right: 40px;
        }
        &__txt {
          opacity: 1;
          width: auto;
          pointer-events: auto;
          padding-left: 12px;
        }
        &__item {
          &--active {
            .header-menu-nav {
              &__main-link {
                &::before {
                  opacity: 1;
                }
              }
            }
          }
        }
        &__item-with-sub {
          &.header-menu-nav__item--active {
            .header-menu-nav {
              &__sub {
                padding-left: 50px;
              }
              &__sub-wrap {
                border-color: #C4C4C4;
              }
            }
          }
        }
        &__sub {
          padding-left: 0;
          &::before {
            opacity: 0;
          }
        }
        &__sub-item {
          text-align: left;
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}
@media (max-width: $desktop) {
  .wrapper-rubber {
    main {
      .container {
        padding-left: $grid-spacing;
      }
    }
    &--tablet-long {
      .wrapper-rubber {
        &__main {
          padding-left: $header-menu-width-sm;
        }
      }
      .header-menu {
        width: $header-menu-width-sm;
      }
      .header-menu-nav {
        &__main-link {
          padding-left: 20px;
          padding-right: 20px;
        }
        &__item-with-sub {
          &--active {
            .header-menu-nav {
              &__sub {
                padding-left: 30px;
              }
            }
          }
        }
      }
      .header-manager {
        &__wrap {
          padding-bottom: 20px;
        }
      }
    }
  }
}
@media (max-width: $tablet) {
  .wrapper-rubber {
    &__main {
      padding-left: 0;
    }
    &--tablet-long {
      .wrapper-rubber {
        &__main {
          padding-left: 0;
        }
      }
    }
    main {
      .container {
        padding-left: $grid-spacing-sm;
      }
    }
  }
  .split-wrapper {
    display: block;
    padding-left: $grid-spacing-sm;
    padding-right: $grid-spacing-sm;
    &__left {
      display: none;
    }
    &__right {
      width: auto;
      padding-top: 129px;
    }
  }
  .page-text-container {
    max-width: none;
  }
  .block-text-img {
    display: block;
    &__text {
      max-width: none;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &--long {
      .block-text-img {
        &__text {
          max-width: none;
          margin-right: 0;
        }
      }
    }
  }
  .math-sign {
    margin: 0 8px;
  }
  .formula {
    padding: 40px 10px 40px;
  }
}
@media (max-width: $mobile-big) {
  .main-links {
    align-self: normal;
    overflow-x: auto;
    padding-bottom: 7px;
    margin-left: -$grid-spacing-sm;
    margin-right: -$grid-spacing-sm;
    padding-left: $grid-spacing-sm;
    &__wrap {
      white-space: nowrap;
      position: relative;
      min-width: $grid-spacing-sm;
      &::after {
        content: '';
        position: relative;
        display: inline-block;
        width: $grid-spacing-sm;
        background-color: $grey-light;
        height: 1px;
      }
    }
  }
  .fixed-link {
    width: 40px;
    height: 40px;
    right: $grid-spacing-sm;
    bottom: 40px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .formula {
    &.u-dfc {
      display: none;
    }
  }
  .page-text-container {
    font-size: 16px;
    line-height: 24px;
    .main-links {
      margin-bottom: 16px;
      & + .block-text-img {
        margin-top: 16px;
      }
    }
    ul,ol {
      margin-bottom: $line-height-sm;
    }
  }
  .block-text-img {
    &__img {
      padding-left: 17px;
    }
    img {
      width: 100%;
      max-width: 490px;
    }
    &--long {
      .block-text-img {
        &__img {
          padding-left: 0;
        }
      }
    }
  }
}
@media (max-width: $mobile) {
  .link-border {
    font-size: 16px;
    line-height: 19px;
  }
  .block-text-img {
    &__img {
      padding-left: 8px;
    }
  }
}
@media (max-width: $mobile-mini) {
  .block-text-img {
    img {
      max-width: 250px;
    }
  }
}
