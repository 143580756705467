@import "app/javascript/packs/scss/variables";
.page-top {
  margin-bottom: 50px;

  @media (max-width: $mobile-big) {
    margin-bottom: 30px;
  }

  h2 {
    margin-bottom: 32px;
    opacity: .8;
  }
  .info-slider {
    margin-top: 14px;
  }
  .page-nav-slider {
    margin-top: 30px;
  }
}
