@import "app/javascript/packs/scss/variables";
@import "app/javascript/packs/scss/mixins";
.i-select {
  position: relative;
  display: inline-block;
  width: 248px;
  height: 35px;
  font-size: 16px;
  line-height: 24px;
  &--active {
    .i-select {
      &__main {
        svg {
          transform: scaleY(-1);
        }
      }
      &__drop {
        transform: scale(1);
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &--error {
    .i-select__main {
      border-color: red;
    }
  }
  &__main {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 18px;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: 0px 8.77813px 10.8397px rgba(123, 141, 164, 0.0525061), 0px 3.65278px 4.51063px rgba(123, 141, 164, 0.0365489);
    cursor: pointer;
    transition: border-color .2s linear;
    svg {
      flex-shrink: 0;
      transition: .25s linear;
      transition-property: transform;
      margin-left: 12px;
    }
  }
  &__arrow {
    display: flex;
  }
  &__selected {
    white-space: nowrap;
    overflow: hidden;
    max-width: calc(100% - 26px);
    text-overflow: ellipsis;
    &:empty {
      &::before {
        content: attr(data-placeholder);
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
        color: #333;
        font-weight: inherit;
      }
    }
  }
  &__drop {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    transform: scale(.85);
    visibility: hidden;
    opacity: 0;
    transition: .2s linear;
    transition-property: transform, opacity, visibility;
    transform-origin: top center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #fff;
    margin-top: 15px;
    z-index: 2;
    margin-bottom: 0;
  }
  &__drop-inner {
    max-height: 305px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    @include scrollbar();
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      overflow: auto;
    }
  }
  &__list {
    list-style: none;
    padding: 12px 0;
    &.new {
      display: none;
    }
  }
  &__list-inner {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 13px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__item {
    transition: .25s linear;
    transition-property: background-color;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 7px 10px 7px 40px;
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    &--active {
      background-color: $grey;
    }
    &--hide {
      display: none;
    }
    &:hover, &:focus {
      background-color: $grey;
    }
    &.focus {
      background-color: $grey;
    }
  }
  &__item-group {
    font-size: 14px;
    line-height: 18px;
    color: $text-color-opacity-30;
    padding-left: 40px;
    padding-right: 10px;
  }
  &__search {
    position: relative;
    padding: 20px 20px 0;
    input {
      width: 100%;
      background-color: rgba(#ECF5FB,.8);
      border-color: rgba(#ECF5FB,.8);
      box-shadow: none;
      padding-left: 40px;
      @include placeholder {
        color: $text-color-opacity-30;
      }
    }
    img {
      position: absolute;
      left: 40px;
      top: 40px;
    }
  }
  &--lg {
    height: 54px;
  }
  &--sm {
    height: 36px;
    font-size: 16px;
    line-height: 24px;
    text-transform: lowercase;
    .i-select {
      &__main {
        justify-content: space-between;
        background-color: $grey;
        border-color: $grey;
        box-shadow: none;
        padding-left: 14px;
        padding-right: 10px;
        svg {
          margin-left: 8px;
        }
      }
      &__selected {
        max-width: calc(100% - 20px);
      }
      &__drop {
        background-color: $grey;
        border-color: $grey;
        border-radius: 5px;
        margin-top: 5px;
      }
      &__drop-inner {
        @include scrollbar2();
        border-radius: 5px;
        max-height: 166px;
      }
      &__list {
        padding: 6px 0;
      }
      &__item {
        padding: 0 5px 0 15px;
        white-space: nowrap;
        margin-bottom: 6px;
        &:last-child {
          margin-bottom: 0;
        }
        @media (hover: hover) {
          &:hover {
            background-color: $grey-light;
          }
        }
      }
    }
  }
}

@media (max-width: $mobile-mini) {
  .i-select {
    &__item {
      padding-left: 17px;
    }
  }
}
