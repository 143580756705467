@import "app/javascript/packs/scss/variables";
.our-projects {
  padding-top: 118px;
  padding-bottom: 142px;

  @media (max-width: $mobile-big) {
    padding-top: 37px;
    padding-bottom: 29px;
  }
  @media (max-width: $mobile) {
    padding-bottom: 40px;
  }
  @media (max-width: $mobile-400) {
    padding-bottom: 29px;
  }

  h2 {
    @media (max-width: $tablet) {
      br {
        display: none;
      }
    }
  }

  &__content-block {
    position: relative;
    max-width: 660px;
    padding-right: 90px;

    @media (max-width: $mobile-big) {
      max-width: none;
      padding-right: 0;
    }
  }
  &__txt-block {
    margin-bottom: 28px;
    h2 {
      margin-bottom: 45px;

      @media (max-width: $mobile-big) {
        margin-bottom: 33px;
      }
    }
    p {
      max-width: 425px;

      @media (max-width: $mobile-big) {
        max-width: none;
        padding-right: 12px;
      }
    }

    @media (max-width: $mobile-big) {
      margin-bottom: 40px;
    }
  }
  &__grid {
    @media (max-width: $mobile-big) {
      position: relative;
      display: grid;
      grid-template-areas: 'adv img2' 'img1 img1';
      grid-template-columns: auto 1fr;
    }
  }
  &__features {
    display: flex;
    align-items: center;
    @media (max-width: $mobile-big) {
      grid-area: adv;
      display: grid;
      grid-gap: 20px;
    }
    @media (max-width: $mobile) {
      min-width: 210px;
    }
  }
  &__features-item {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.03em;
    margin-right: 31px;
    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $mobile-big) {
      margin: 0;
    }
  }
  &__features-item-ic {
    flex-shrink: 0;
    margin-right: 10px;

    @media (max-width: $mobile-big) {
      margin-right: 10px;
      font-size: 0;
      line-height: 1;

      img {
        display: block;
        margin: auto;
      }
    }
  }

  &__img-block1,
  &__img-block2 {
    position: absolute;
    filter: $drop-shadow-main;
    display: flex;
    border: 12px solid #FFFFFF;
    border-radius: 20px;
    background-color: #fff;
  }
  &__img-block1 {
    left: calc(100% + 71px);
    top: -92px;
    @media (max-width: $tablet) {
      position: static;
      margin-top: 30px;
    }
    @media (max-width: $mobile-big) {
      grid-area: img1;
      max-width: 100%;
      min-width: 340px;
    }
    @media (max-width: $mobile) {
      margin-left: -10px;
    }
  }
  &__img-block2 {
    left: calc(100% - 25px);
    top: 14px;
    @media (max-width: $tablet) {
      top: 102px;
    }
    @media (max-width: $mobile-big) {
      grid-area: img2;
      top: -13px;
      left: 20vw;
    }
    @media (max-width: $mobile) {
      width: 42.49vw;
      left: 10vw;
      max-width: 215px;
    }
    @media (max-width: $mobile-400) {
      width: 44.49vw;
      top: -36px;
      left: 3vw;
    }
  }
  &__img1, &__img2 {
    max-width: none;
    border-radius: 10px;
  }
  &__img1 {
    @media (max-width: $tablet) {
      max-width: 100%;
    }
  }
  &__img2 {
    @media (max-width: $mobile-big) {
      max-width: 100%;
    }
  }
}
