@import "app/javascript/packs/scss/variables";
.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 14px;
  line-height: 18px;
  height: 54px;
  min-width: 150px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  background-color: $green;
  box-shadow: 0px 29.4839px 36.4082px rgba(123, 141, 164, 0.0774939), 0px 8.77813px 10.8397px rgba(123, 141, 164, 0.0525061), 0px 3.65278px 4.51063px rgba(123, 141, 164, 0.0365489);
  margin: 0;
  padding: 0 20px;
  z-index: 1;
  transition: .25s linear;
  transition-property: color, background-color, border-color, box-shadow;
  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 55.1464px 68.0975px rgba(123, 141, 164, 0.0934511), 0px 29.4839px 36.4082px rgba(123, 141, 164, 0.0774939), 0px 16.5284px 20.4101px rgba(123, 141, 164, 0.065), 0px 8.77813px 10.8397px rgba(123, 141, 164, 0.0525061), 0px 3.65278px 4.51063px rgba(123, 141, 164, 0.0365489);
    }
  }
  &:active {
    box-shadow: 0px 16.5284px 20.4101px rgba(123, 141, 164, 0.065), 0px 8.77813px 10.8397px rgba(123, 141, 164, 0.0525061), 0px 3.65278px 4.51063px rgba(123, 141, 164, 0.0365489), inset 0px 0px 20px rgba(105, 105, 105, 0.14);
  }
  &.disabled,
  &[disabled] {
    background: rgba(0, 168, 137, 0.3);
    box-shadow: 0px 29.4839px 36.4082px rgba(123, 141, 164, 0.0774939), 0px 8.77813px 10.8397px rgba(123, 141, 164, 0.0525061), 0px 3.65278px 4.51063px rgba(123, 141, 164, 0.0365489);
    cursor: not-allowed;
  }
  &--white {
    background-color: #fff;
    color: $text-color;
    &.disabled,
    &[disabled] {
      background-color: rgba(255,255,255,.5);
    }
  }
  &--alert {
    background-color: $red;
    color: #fff;
  }
  &--filter {
    font-size: 16px;
    background-color: $orange;
    color: #fff;
  }
}

.btn-sm {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-weight: bold;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: -0.03em;
  color: $text-color-opacity-50;
  background-color: #DFEDF5;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  height: 35px;
  padding: 0 13px;
  transition: .25s linear;
  transition-property: color,box-shadow;
  span {
    margin-left: 8px;
  }
  @media (hover: hover) {
    &:hover {
      color: $text-color;
      box-shadow: 0px 55.1464px 68.0975px rgba(123, 141, 164, 0.0934511), 0px 29.4839px 36.4082px rgba(123, 141, 164, 0.0774939), 0px 16.5284px 20.4101px rgba(123, 141, 164, 0.065), 0px 8.77813px 10.8397px rgba(123, 141, 164, 0.0525061), 0px 3.65278px 4.51063px rgba(123, 141, 164, 0.0365489);
    }
  }
  &:active {
    box-shadow: 0px 16.5284px 20.4101px rgba(123, 141, 164, 0.065), 0px 8.77813px 10.8397px rgba(123, 141, 164, 0.0525061), 0px 3.65278px 4.51063px rgba(123, 141, 164, 0.0365489), inset 0px 0px 20px rgba(105, 105, 105, 0.14);
  }
  &.disabled,
  &[disabled] {
    background-color: rgba(223, 237, 245, 0.5);
    cursor: not-allowed;
    opacity: .2;
    color: $text-color-opacity-50;
    box-shadow: none;
  }
  &--lg {
    width: 40px;
    height: 40px;
    padding: 0;
  }
  &--filter {
    font-size: 16px;
    background-color: $orange;
    color: #fff;
    font-weight: normal;
  }
}
